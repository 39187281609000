import * as React from "react"
import {titleIcon, titleIconSecondary} from "../css/index.module.css"

const BrandedDivider = () => {
  return (
    <span className="px-2">
      <span className={titleIcon}>/</span><span className={titleIconSecondary}>/</span>
    </span>
  )
}

export default BrandedDivider
