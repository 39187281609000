import * as React from 'react'
import {useForm, ValidationError} from '@formspree/react'
import {form, inputLabel, invalid, confirmation} from "../css/form.module.css"
import ReCAPTCHA from "react-google-recaptcha"

const ContactForm = () => {
  const [state, handleSubmit] = useForm("mrgdaooa")
  const [formData, setFormData] = React.useState({
    email: "",
    message: "",
    email_valid: true,
    form_valid: false,
    recaptcha_response: ""
  })

  React.useEffect(() => {
    setFormData({...formData, form_valid: formValid()})
  }, [formData.recaptcha_response, formData.email, formData.message])

  const errorMessages = () => {
    if (state.errors.length) {
      return state.errors.map(e => e.message).join(" ")
    } else {
      return ""
    }
  }

  const handleRecaptchaChange = (value) => {
    setFormData({...formData, recaptcha_response: value || ""})
  }

  const formValid = () => {
    return emailValid(formData.email) &&
      formData.message.length > 0 &&
      formData.recaptcha_response.length > 0
  }

  const handleCustomSubmit = (e) => {
    e.preventDefault();

    if (formData.form_valid) {
      handleSubmit(e)
    }
  }

  const handleEmailChange = (e) => {
    if (e.target.value.length) {
      setFormData({
        ...formData,
        email: e.target.value,
        email_valid: emailValid(e.target.value)
      })
    }
  }

  const handleMessageChange = (e) => {
    setFormData({...formData, message: e.target.value})
  }

  const emailValid = (val) => {
    return !!val.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
  }

  const disableSubmit = () => {
    return state.submitting || !formData.form_valid
  }

  return (
    state.succeeded ?
      <div className={confirmation}>Thanks for contacting us, we'll be in touch.</div> :
      <form className={form} onSubmit={handleCustomSubmit}>
        <div className="form-group d-flex flex-column py-4">
          <label id="emailLabel" className={`${inputLabel} ${formData.email_valid ? "" : invalid}`} htmlFor="email">
            {formData.email_valid ? "Email Address" : "Email Address - please enter a valid email address"}
          </label>
          <input
            id="email"
            type="email"
            name="email"
            onChange={handleEmailChange}
          />
          <ValidationError
            prefix="Email"
            field="email"
            errors={state.errors}
          />
        </div>

        <div className="form-group d-flex flex-column py-4">
          <label className={inputLabel} htmlFor="message">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            onChange={handleMessageChange}
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
        </div>

        <div>
          {errorMessages()}
        </div>

        <ReCAPTCHA sitekey="6LfOmKoiAAAAAJp6vw-JxbKsXczu-O52yPyTGpg_" onChange={handleRecaptchaChange}/>

        <button className="btn btn-outline-secondary mt-4" type="submit" disabled={disableSubmit()}>
          Submit
        </button>
      </form>
  )
}

export default ContactForm
