import * as React from "react"
import Layout from "../components/layout"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { cardTitle } from "../css/index.module.css"
import ContactForm from "../components/contact_form"
import BrandedDivider from "../components/branded_divider"
import { SEO } from "../components/seo"
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useEffect, useRef } from "react";


const ContactPage = () => {
  const ref = useRef();

  const MyMapComponent = ({ center, zoom }) => {
    useEffect(() => {
      new window.google.maps.Map(ref.current, {
        center,
        zoom,
      });
    }, []);

    return <div style={{ height: '100%', width: '100%', minHeight: "300px" }} ref={ref} id="map"/>;
  }

  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return <div>Loading map</div>;
      case Status.FAILURE:
        return <div>Unable to load map</div>;
      case Status.SUCCESS:
        return <MyMapComponent center={{
          lat: 28.068396786467005,
          lng: -82.46268457053961
        }} zoom={10} />
    }
  };

  // <Marker lat={28.068396786467005} lng={-82.46268457053961}/>

  return (
    <Layout pageTitle="Contact">
      <Container fluid>
        <Row className="no-gutters">
          <Col className="px-0" lg={6}>
            <div style={{ height: '100%', width: '100%', minHeight: "300px" }}>
              <Wrapper apiKey={"AIzaSyB_vVBI-63wqcFyRCoLXL4KerDiP_hYo8I"}
                       render={render}
                       libraries={["geometry", "drawing", "places"]}/>

            </div>
          </Col>
          <Col className="p-5" lg={6}>
            <h4 className={cardTitle}><BrandedDivider/>Contact Us</h4>
            <ContactForm/>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}


export const Head = () => <SEO title={"Enginuity GC | About"}/>

export default ContactPage
